<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!---------->
    <toolbar
      :items="datatableItems"
      @alert="openAlertDialog"
      @search="search"
      @adv-search="advancedSearch"
      @clear-search="clearSearch"
    />
    <datatable
      :search="searchObject"
      @items="getDatatableItems"
      @actions="openActionsDialog"
      @details="openDetailsDialog"
      @movement="openMovementDialog"
      @images="openImagesCarousel"
      @qr="openQrDialog"
    />
    <movement-dialog ref="movementDialog" />
    <qr-dialog ref="qrDialog" />
    <img-carousel ref="imgCarousel" />
    <details-dialog
      ref="detailsDialog"
      @close="''"
      @delete-applicability="getParts"
    />
    <actions-dialog
      ref="actionsDialog"
      @close="getParts"
    />
    <alert-dialog ref="alertDialog" />
  </v-container>
</template>

<script>
import Datatable from '@/components/DamagedParts/datatable.vue';
import Toolbar from '@/components/DamagedParts/toolbar.vue';
import ActionsDialog from '@/components/DamagedParts/actionsDialog.vue';

import AlertDialog from '@/components/alertDialog.vue';
import DetailsDialog from '@/components/detailsDialog.vue';
import MovementDialog from '@/components/movementDialog.vue';
import ImgCarousel from '@/components/imgCarousel.vue';
import QrDialog from '@/components/qrDialog.vue';

export default {
  components: {
    datatable: Datatable,
    toolbar: Toolbar,
    // 'edit-dialog': EditDialog,
    'actions-dialog': ActionsDialog,
    'alert-dialog': AlertDialog,
    'details-dialog': DetailsDialog,
    'movement-dialog': MovementDialog,
    // 'id-changer': IdChanger,
    // 'img-dialog': ImgDialog,
    'img-carousel': ImgCarousel,
    'qr-dialog': QrDialog,
  },
  data: () => ({
    hideEditDialog: false,
    searchObject: {
      type: null,
      data: {},
    },
    datatableItems: [],
  }),
  methods: {
    getParts() {
      // Fired when closing edit and actions dialog.
      if (this.searchObject.type === null) {
        this.clearSearch();
      } else if (this.searchObject.type === 'regularSearch') {
        this.search(this.searchObject.data);
      } else if (this.searchObject.type === 'advancedSearch') {
        this.advancedSearch(this.searchObject.data);
      }
    },
    /* Search ------------------------ */
    search(data) {
      this.searchObject = {
        type: 'regularSearch',
        data,
      };
    },
    advancedSearch(data) {
      this.searchObject = {
        type: 'advancedSearch',
        data,
      };
    },
    clearSearch() {
      this.searchObject = {
        type: null,
        data: {},
      };
    },
    /* Datatable Items --------------- */
    getDatatableItems(data) {
      this.datatableItems = data;
    },
    /* Details Dialog ------------------- */
    openDetailsDialog(data) {
      const { detailsDialog } = this.$refs;
      detailsDialog.open(data);
    },
    /* Actions Dialog ------------------- */
    openActionsDialog(data) {
      const { actionsDialog } = this.$refs;
      actionsDialog.open(data);
    },
    openMovementDialog(data) {
      const { movementDialog } = this.$refs;
      movementDialog.open(data);
    },
    /* Img Carousel ------------------ */
    openImagesCarousel(data) {
      const { imgCarousel } = this.$refs;
      imgCarousel.open(data);
      console.log(data);
    },
    /* QR Dialog --------------------- */
    openQrDialog(data) {
      const { qrDialog } = this.$refs;
      qrDialog.open(data);
    },
    /* Alert dialog ----------------------------------------------------------------------------- */
    openAlertDialog(data) {
      const { alertDialog } = this.$refs;
      alertDialog.open(data);
    },
  },
};
</script>
