<template>
  <v-dialog
    v-model="dialog"
    max-width="1200px"
    height="300px"
  >
    <v-card>
      <!-----------------------------------------TITLE BAR----------------------------------------->
      <v-card-title class="redColor">
        <!--TITLE-->
        <span class="headline">
          {{ $t('parts.inventory.search_list') }}
        </span>

        <v-spacer />

        <!--CLOSE BUTTON-->
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <!------------------------------------------------------------------------------------------->
      <v-card-text>
        <v-layout
          justify-center
          wrap
        >
          <!--SEARCH FIELD-->
          <v-flex
            xs12
            sm8
          >
            <v-text-field
              v-model="search"
              :label="$t('parts.inventory.search')"
              :loading="loadingGroups"
              solo
              color="grey darken-4"
              append-icon="search"
              single-line
              hide-details
              @keyup.enter="searchMethod"
              @click:append="searchMethod"
            />
          </v-flex>

          <!---->
          <v-flex xs6>
            <v-layout
              v-if="loadingGroups"
              align-center
              justify-center
            >
              <v-progress-circular

                class="ma-4"
                :size="50"
                color="red darken-2"
                indeterminate
              />
            </v-layout>

            <v-list
              v-else
              subheader
            >
              <v-subheader inset>
                Groups
              </v-subheader>

              <v-list-tile
                v-for="group in partGroups"
                :key="group._id"
                @click="getTitles(group)"
              >
                <v-list-tile-content>
                  <v-list-tile-title v-text="group.title[$store.getters.language]" />
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-btn
                    icon
                    @click="searchGroup(group)"
                  >
                    <v-icon>
                      open_in_new
                    </v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-flex>
          <!---->
          <v-flex xs6>
            <v-layout
              v-if="loadingTitles"
              align-center
              justify-center
            >
              <v-progress-circular

                class="ma-4"
                :size="50"
                color="red darken-2"
                indeterminate
              />
            </v-layout>

            <v-list
              v-else
              two-line
              subheader
            >
              <v-subheader inset>
                Titles
              </v-subheader>

              <v-list-tile
                v-for="item in titles"
                :key="item._id"
                @click="searchTitle(item)"
              >
                <v-list-tile-content>
                  <v-list-tile-title>
                    <span> {{ item.title[$store.getters.language] }} </span>
                  </v-list-tile-title>


                  <v-list-tile-sub-title>
                    <span
                      v-for="(element, index) in item.related"
                      :key="index"
                    >
                      {{ element.title[$store.getters.language] }} &nbsp;
                    </span>
                  </v-list-tile-sub-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <span> {{ item.parts.count ? item.parts.count : 0 }} </span>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';


export default {
  components: {},
  data: () => ({
    dialog: false,
    search: '',
    loadingGroups: false,
    loadingTitles: false,
    partGroups: [],
    titles: [],
  }),
  watch: {
    dialog(value) { if (!value) this.close(); },
    search(value) {
      if (value) this.searchMethod();
      else { this.partGroups = []; }
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.search = '';
      this.loadingGroups = false;
      this.loadingTitles = false;
      this.partGroups = [];
      this.titles = [];

      this.$emit('close');
    },
    searchMethod() {
      this.loadingGroups = true;
      this.titles = [];
      getRequest('/info-car/populatedpartgroups', `title=${this.search}&language=${this.$store.getters.language}`)
        .then((response) => { this.partGroups = response.partgroups; })
        .catch(error => console.log(error))
        .finally(() => { this.loadingGroups = false; });
    },
    searchGroup(group) {
      this.$emit('group', { group });
      this.close();
    },
    getTitles(group) {
      this.loadingTitles = true;
      getRequest('/info-car/populatedpartgroup', `id=${group._id}&role=${this.$store.getters.role}&institution_id=${this.$store.getters.institutionId}`)
        .then((response) => { this.titles = response.parts; })
        .catch(error => console.log(error))
        .finally(() => { this.loadingTitles = false; });
    },
    searchTitle(title) {
      this.$emit('title', { title });
      this.close();
    },
  },
};
</script>
